var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    { staticClass: "table" },
    [
      _c(
        "el-row",
        { staticClass: "row-bg" },
        [
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              this.feeConsumValue === 1
                ? _c(
                    "el-select",
                    {
                      staticClass: "tb-smselect",
                      staticStyle: { width: "200px" },
                      attrs: {
                        multiple: "",
                        "collapse-tags": "",
                        placeholder: "请选择校区",
                      },
                      model: {
                        value: _vm.schoolValue,
                        callback: function ($$v) {
                          _vm.schoolValue = $$v
                        },
                        expression: "schoolValue",
                      },
                    },
                    _vm._l(_vm.schoolArray, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  )
                : _vm._e(),
              this.feeConsumValue === 2
                ? _c(
                    "el-select",
                    {
                      staticClass: "tb-smselect",
                      staticStyle: { width: "230px" },
                      attrs: {
                        multiple: "",
                        "collapse-tags": "",
                        placeholder: "请选择课程",
                      },
                      model: {
                        value: _vm.classValue,
                        callback: function ($$v) {
                          _vm.classValue = $$v
                        },
                        expression: "classValue",
                      },
                    },
                    _vm._l(_vm.classArray, function (item) {
                      return _c("el-option", {
                        key: item.course_id,
                        attrs: { label: item.name, value: item.course_id },
                      })
                    }),
                    1
                  )
                : _vm._e(),
              _c(
                "el-select",
                {
                  staticClass: "tb-smselect",
                  staticStyle: { width: "150px" },
                  attrs: { placeholder: "请选择统计方式" },
                  on: { change: _vm.handleSelectChange },
                  model: {
                    value: _vm.feeConsumValue,
                    callback: function ($$v) {
                      _vm.feeConsumValue = $$v
                    },
                    expression: "feeConsumValue",
                  },
                },
                _vm._l(_vm.feeArray, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  staticClass: "tb-button",
                  attrs: { type: "primary", size: "medium" },
                  on: { click: _vm.gotoSearch },
                },
                [_vm._v("搜索")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "tb-button",
                  attrs: { type: "primary", size: "medium" },
                  on: { click: _vm.reset },
                },
                [_vm._v("重置")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticClass: "tb-list",
          staticStyle: { width: "50%" },
          attrs: {
            border: "",
            fit: "",
            "highlight-current-row": "",
            data: _vm.dataList,
          },
        },
        [
          this.feeConsumValue === 1
            ? _c("el-table-column", {
                attrs: { label: "校区", align: "center", prop: "campusName" },
              })
            : _vm._e(),
          this.feeConsumValue === 2
            ? _c("el-table-column", {
                attrs: { label: "课程", align: "center", prop: "productName" },
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: {
              label: "课消数量（课时）",
              align: "center",
              prop: "amount",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "课消金额(元)", align: "center", prop: "feeMoney" },
          }),
          _c("el-table-column", {
            attrs: {
              label: "欠费金额(元)",
              align: "center",
              prop: "lessMoney",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }