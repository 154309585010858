<template>
  <el-row class="table">
    <el-row class="row-bg">
      <el-col :span="8">
        <el-select
          multiple
          collapse-tags
          v-model="schoolValue"
          v-if="this.feeConsumValue === 1"
          placeholder="请选择校区"
          class="tb-smselect"
          style="width: 200px;"
        >
          <el-option v-for="item in schoolArray" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
        <el-select
          multiple
          collapse-tags
          v-model="classValue"
          v-if="this.feeConsumValue === 2"
          placeholder="请选择课程"
          class="tb-smselect"
          style="width: 230px;"
        >
          <el-option
            v-for="item in classArray"
            :key="item.course_id"
            :label="item.name"
            :value="item.course_id"
          ></el-option>
        </el-select>
        <el-select
          @change="handleSelectChange"
          v-model="feeConsumValue"
          placeholder="请选择统计方式"
          class="tb-smselect"
          style="width: 150px;"
        >
          <el-option
            v-for="item in feeArray"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-button type="primary" size="medium"  @click="gotoSearch" class="tb-button">搜索</el-button>
        <el-button type="primary" size="medium" @click="reset" class="tb-button">重置</el-button>
      </el-col>
    </el-row>
    <el-table
      border
      fit
      highlight-current-row
      :data="dataList"
      class="tb-list"
      style="width:50%"
    >
      <el-table-column label="校区" v-if="this.feeConsumValue === 1" align="center" prop="campusName"></el-table-column>
      <el-table-column label="课程" v-if="this.feeConsumValue === 2"  align="center" prop="productName"></el-table-column>
      <el-table-column label="课消数量（课时）" align="center" prop="amount"></el-table-column>
      <el-table-column label="课消金额(元)" align="center" prop="feeMoney"></el-table-column>
      <el-table-column label="欠费金额(元)" align="center" prop="lessMoney"></el-table-column>

    </el-table>
  </el-row>
</template>

<script>
  import { getCourseAll, getCancleProductTotal } from '@/api/statistics'
  import { getCampusAll } from '@/api/senate/class'

  export default {
    data () {
      return {
        tips: '请输入姓名',
        visible: true,
        schoolValue: [],
        classValue: [],
        schoolArray: [],
        classArray: [],
        select: '1',
        feeConsumValue:1,
        feeArray: [
          {
            label: '按校区统计',
            value: 1
          },
          {
            label: '按课程统计',
            value: 2
          }
        ],
        dataList: [],
        loginName: '',
        name: '',
        orderMoneyTotal:{},
        exportExcelFlag:false
      }
    },
    created () {
      this.getCampusAll()
      this.getCourseAll()
      this.getCancleProductTotal()
    },
    methods: {
      handleSelectChange() {
        this.schoolValue = []
        this.classValue = []
        this.getCancleProductTotal()
      },
      gotoSearch(){
        this.getCancleProductTotal()
      },
      reset () {
        this.feeConsumValue = 1
        this.schoolValue = []
        this.classValue = []
        this.loginName = ''
        this.name = ''
        this.select = '1'
      },
      getCampusAll() {
        getCampusAll().then(res => {
          if (res.state === 'success') {
            this.schoolArray = res.body
          }
        })
      },
      getCourseAll () {
        getCourseAll().then(res => {
          if (res.state === 'success') {
            this.classArray = res.body
          }
        })
      },

      getCancleProductTotal () {
        this.dataList = []
        getCancleProductTotal({
          campusSet:this.schoolValue,
          productSet:this.classValue,
          groupBy:this.feeConsumValue == 1?"campusId":"productId"
        }).then(res => {
          if (res.state === 'success') {
            this.dataList = res.body
          }
        })
      },

      getStateColor (state) {
        switch (state) {
          case '正常':
            return '#008000'
          case '异常':
            return '#FF0000'
          default:
            return '#f5c319'
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "../../../style/table.scss";
</style>
