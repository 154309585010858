var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-tabs",
    {
      on: { "tab-click": _vm.handleClick },
      model: {
        value: _vm.activeName,
        callback: function ($$v) {
          _vm.activeName = $$v
        },
        expression: "activeName",
      },
    },
    [
      _c(
        "el-tab-pane",
        { attrs: { label: "课消统计", name: "first" } },
        [_c("consume-statistic")],
        1
      ),
      _c(
        "el-tab-pane",
        { attrs: { label: "学员课消统计", name: "second" } },
        [_c("student-consum-detail")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }