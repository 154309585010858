<template>
  <el-row class="table">
    <el-row class="row-bg">
      <el-col :span="18">
      <el-select
        multiple
        collapse-tags
        v-model="schoolValue"
        placeholder="请选择校区"
        class="tb-smselect"
        style="width: 200px;"
      >
        <el-option v-for="item in schoolArray" :key="item.id" :label="item.name" :value="item.id"></el-option>
      </el-select>
      <el-select
        multiple
        collapse-tags
        v-model="classValue"
        placeholder="请选择课程"
        class="tb-smselect"
        style="width: 210px;"
      >
        <el-option
          v-for="item in classArray"
          :key="item.course_id"
          :label="item.name"
          :value="item.course_id"
        ></el-option>
      </el-select>
      <el-select
        v-model="courseConsumValue"
        placeholder="请选择课消金额"
        class="tb-smselect"
        style="width: 150px;"
      >
        <el-option
          v-for="item in typeArray"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
        <el-select
          v-model="feeConsumValue"
          placeholder="请选择欠费金额"
          class="tb-smselect"
          style="width: 150px;"
        >
          <el-option
            v-for="item in feeArray"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      <el-date-picker
        v-model="dataPaker"
        type="daterange"
        unlink-panels
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="yyyy-MM-dd"
        :picker-options="pickerOptions">
      </el-date-picker>
      </el-col>
      <el-col :span="4">
      <el-button type="primary" size="medium"  @click="gotoSearch" class="tb-button">搜索</el-button>
      <el-button type="primary" size="medium" @click="reset" class="tb-button">重置</el-button>
      <el-button type="primary" size="medium" @click="export2Excel" class="tb-button">导出</el-button>
      </el-col>
    </el-row>

    <el-table
      border
      fit
      :summary-method="getSummaries"
      show-summary
      highlight-current-row
      :data="dataList"
      class="tb-list"
    >
      <el-table-column label="学号" align="center" prop="loginName"></el-table-column>
      <el-table-column label="姓名" align="center" prop="name"></el-table-column>
      <el-table-column label="课程" align="center" prop="productName"></el-table-column>
      <el-table-column label="课消校区" align="center" prop="campusName"></el-table-column>
      <el-table-column label="班级" align="center" prop="className"></el-table-column>
      <el-table-column label="上课时间" align="center" prop="startTime"></el-table-column>
      <el-table-column label="课消数量（课时）" align="center" prop="amount"></el-table-column>
      <el-table-column label="课消金额（元）" align="center" prop="feeMoney"></el-table-column>
      <el-table-column label="欠费金额（元）" align="center" prop="lessMoney"></el-table-column>
    </el-table>


    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.currentPage"
      :page-sizes="pagination.pageSizes"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :total="this.pagination.total"
    ></el-pagination>
  </el-row>
</template>

<script>
  import { getCourseAll,getCancleProductInfo, getCancleProductTotal } from '@/api/statistics'
  import { getCampusAll } from '@/api/senate/class'

  export default {
    data () {
      return {
        tips: '请输入姓名',
        visible: true,
        schoolValue: [],
        classValue: [],
        courseConsumValue: "",
        feeConsumValue:"",
        schoolArray: [],
        classArray: [],
        typeArray: [
          {
            label: '大于0',
            value: 1
          },
          {
            label: '等于0',
            value: 2
          }
        ],
        feeArray: [
          {
            label: '大于0',
            value: 1
          },
          {
            label: '等于0',
            value: 2
          }
        ],
        select: '1',
        pagination: {
          currentPage: 1,
          pageSize: 10,
          pageSizes: [10, 30, 50, 100],
          layout: 'total, sizes, prev, pager, next, jumper',
          total: 0
        },
        dataList: [],
        loginName: '',
        name: '',
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        dataPaker:[],
        orderMoneyTotal:{},
        exportExcelFlag:false
      }
    },
    created () {
      this.getCampusAll()
      this.getCourseAll()
      this.getCancleProductInfo()
    },
    methods: {
      formatJson(filterVal, jsonData) {
        return jsonData.map(v => filterVal.map(j => v[j]))
      },
      export2Excel() {
        this.exportExcelFlag = true
        this.pagination.currentPage = 1
        this.pagination.pageSize = 100000
        this.getCancleProductInfo()
      },
      getSummaries(param) {
        const { columns } = param;
        const sums = [];
        columns.forEach((value,index) => {
          if (index === 5) {
            sums[index] = '合计';
          }else if(index > 0 && index <5) {
            sums[index] = '';
          }else{
            if(this.orderMoneyTotal != null ) {
              sums[6] = this.orderMoneyTotal.amount
              sums[7] = this.orderMoneyTotal.feeMoney
              sums[8] = this.orderMoneyTotal.lessMoney
            }
          }
        })
        return sums;
      },
      gotoSearch(){
        this.pagination.currentPage = 1
        this.getCancleProductInfo()
      },
      reset () {
        this.feeConsumValue =  "",
        this.courseConsumValue =  "",
        this.schoolValue = []
        this.dataPaker = []
        this.classValue = []
        this.loginName = ''
        this.name = ''
        this.select = '1'
      },
      getCampusAll() {
        getCampusAll().then(res => {
          if (res.state === 'success') {
            this.schoolArray = res.body
          }
        })
      },
      getCourseAll () {
        getCourseAll().then(res => {
          if (res.state === 'success') {
            this.classArray = res.body
          }
        })
      },
      getCancleProductTotal () {
        getCancleProductTotal({
          pageNum: this.pagination.currentPage,
          pageSize: this.pagination.pageSize,
          needCount: true,
          minStartTime:this.dataPaker.length === 0 ? "": this.dataPaker[0]+" 00:00:00",
          maxStartTime:this.dataPaker.length === 0 ? "": this.dataPaker[1]+" 23:59:59",
          feeMoney:this.courseConsumValue,
          lessMoney:this.feeConsumValue,
          campusSet:this.schoolValue,
          productSet:this.classValue
        }).then(res => {
          if (res.state === 'success') {
            this.orderMoneyTotal = res.body
          }
        })
      },
      getCancleProductInfo () {
        getCancleProductInfo({
          pageNum: this.pagination.currentPage,
          pageSize: this.pagination.pageSize,
          needCount: true,
          minStartTime:this.dataPaker.length === 0 ? "": this.dataPaker[0]+" 00:00:00",
          maxStartTime:this.dataPaker.length === 0 ? "": this.dataPaker[1]+" 23:59:59",
          feeMoney:this.courseConsumValue,
          lessMoney:this.feeConsumValue,
          campusSet:this.schoolValue,
          productSet:this.classValue
        }).then(res => {
          if (res.state === 'success') {
            this.dataList = res.body.list
            this.pagination.total = res.body.total
            this.getCancleProductTotal()
            if(this.exportExcelFlag) {
              require.ensure([], () => {
                const { export_json_to_excel } = require('../../../vendor/Export2Excel')
                const tHeader = ['学号','姓名','课程','课消校区','班级','上课时间','课消数量（课时）','课消金额（元）','欠费金额（元）'];
                const filterVal = ['loginName', 'name', 'productName','campusName','className', 'startTime', 'amount', 'feeMoney' , 'lessMoney'];
                const list = this.dataList;
                const data = this.formatJson(filterVal,list);
                export_json_to_excel(tHeader, data, '学员课消统计');
              })
              this.exportExcelFlag = false
              this.pagination.currentPage = 1
              this.pagination.pageSize = 10
              this.getCancleProductInfo()
            }
          }
        })
      },

      handleCurrentChange (val) {
        this.pagination.currentPage = val
        this.getCancleProductInfo()
      },
      handleSizeChange (val) {
        this.pagination.pageSize = val
        this.getCancleProductInfo()
      },
      getStateColor (state) {
        switch (state) {
          case '正常':
            return '#008000'
          case '异常':
            return '#FF0000'
          default:
            return '#f5c319'
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "../../../style/table.scss";
</style>
