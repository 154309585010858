<template>
  <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="课消统计" name="first">
      <consume-statistic></consume-statistic>
    </el-tab-pane>
    <el-tab-pane label="学员课消统计" name="second">
      <student-consum-detail></student-consum-detail>
    </el-tab-pane>
  </el-tabs>
</template>
<script>
  import studentConsumDetail from '@/components/statistics/studentConsumDetail'
  import consumeStatistic from '@/components/statistics/consumeStatistic'
  export default {
    data() {
      return {
        activeName: 'first'
      };
    },
    components:{
      studentConsumDetail,
      consumeStatistic
    },
    methods: {
      handleClick(tab, event) {
        console.log(tab, event);
      }
    }
  };
</script>
